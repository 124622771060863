<template>
    <v-layout justify-center>
        <v-form @submit.prevent="userLogin">
            <div class="text-h5 mt-5 mb-5">Login</div>

            <v-item-group>
                <label>Email address</label>
                <v-text-field nput type="email" class="form-control form-control-lg" v-model="user.email" />
            </v-item-group>

            <v-item-group>
                <label>Password</label>
                <v-text-field type="password" class="form-control form-control-lg" v-model="user.password" />
            </v-item-group>

            <v-btn type="submit" dark large color="teal">Login</v-btn>

            <p class="forgot-password text-right mt-2 mb-4">
                <router-link to="/signup">Signup</router-link>
            </p>
        </v-form>
    </v-layout>
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    userLogin () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          alert(error.message)
        })
    }
  }
}
</script>
